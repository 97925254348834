import React, { useState } from "react";
import Card from "../components/ui/Card/Card";
import { Swiper, SwiperSlide } from "swiper/react";
import he from 'he';

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import { Pagination } from "swiper/modules";
import ExchangeForm from "../components/ExchangeForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faUserPlus,
    faShareNodes,
    faLocationDot,
    faPhone,
    faGlobe,
    faXmarkCircle,
    faEnvelope,
    faLanguage,
    faUserClock,
    faCopy,
    faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
    faWhatsapp,
    faSnapchat,
    faInstagram,
    faLinkedin,
    faYoutube,
    faVimeo,
    faTumblr,
    faTiktok,
    faReddit,
    faPinterest,
    faBehance,
    faXTwitter,
    faFacebook,
} from "@fortawesome/free-brands-svg-icons";
import CardFooter from "../components/CardFooter";
import EmbedVideo from "../components/EmbedVideo";
import PaymentsList from "../components/PaymentsList";
import BookAppointment from "../components/BookAppointment";
import Products from "../components/Products";
import Addresses from "../components/Addresses";
import About from "../components/About";

// Import icons from React Icons
import { FaGlobe, FaFacebook, FaWhatsapp, FaTwitter, FaSnapchat, FaInstagram, FaLinkedin, FaYoutube, FaVimeo, FaTumblr, FaTiktok, FaReddit, FaPinterest } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";

import bgDefaultImage from '../images/bg_default.jpeg';

const CardInfo = ({ profileData }) => {

    const [saveToggled, setSaveToggled] = useState(false);
    const [emailToggled, setEmailToggled] = useState(false);
    const [shareToggled, setShareToggled] = useState(false);
    const [displayMails, setDisplayMails] = useState(false);
    const [webToggled, setWebToggled] = useState(false);
    const [displayImage, setDisplayImage] = useState(false);

    const toggleSave = () => setSaveToggled((prevState) => !prevState);
    const toggleEmail = () => setEmailToggled((prevState) => !prevState);
    const toggleShare = () => setShareToggled((prevState) => !prevState);
    const toggleMails = () => setDisplayMails((prevState) => !prevState);
    const toggleWeb = () => setWebToggled((prevState) => !prevState);
    const toggleDisplayImage = () => setDisplayImage((prevState) => !prevState);

    const handleDismissExchangeForm = () => {
        setSaveToggled(false);
    };
    const countNonEmptyFields = (socialMedia) => {
        const nonEmptyCount = Object.values(socialMedia).filter(value => value.trim() !== '').length;
        // Check if more than 13 fields are non-empty(there are 13 social media links available as of now)
        return nonEmptyCount < 13;
    };

    // Manually decode HTML entities
    const manualDecode = (str) => {
        // Replace `&amp;` with `&`
        const tempStr = str.replace(/&amp;/g, '&');
        return he.decode(tempStr);
    };

    // Get initials from name
    const getInitials = (name) => {
        const names = name.split(' ');
        return names.map(n => n[0]).join('').toUpperCase();
    };

    return (
        <main className="max-w-2xl mx-auto">
            {profileData ? (
                <>
                    <div
                        className={`h-full w-full z-20 left-0 bg-black px-5 bg-opacity-50 ${displayImage ? "fixed" : "hidden"
                            }`}
                    >
                        <div className="max-w-2xl h-full relative mx-auto">
                            <FontAwesomeIcon
                                icon={faXmarkCircle}
                                className="top-5 left-5 z-10 absolute cursor-pointer text-white"
                                size="2x"
                                onClick={toggleDisplayImage}
                            />
                            <img
                                src="../images/profile.jpg"
                                className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                            />
                        </div>
                    </div>

                    <div
                        className={`h-full w-full z-20 left-0 bg-black bg-opacity-50 ${saveToggled ? "fixed" : "hidden"
                            }`}
                    >
                        <div className="max-w-2xl h-full relative mx-auto">
                            <div className=" top-5 left-5 absolute flex items-center bg-gray-200 p-2 rounded-full">
                                <FontAwesomeIcon
                                    icon={faXmarkCircle}
                                    className=" cursor-pointer text-red-500 "
                                    size="2x"
                                    onClick={toggleSave}
                                />
                                <div onClick={toggleSave} className=" cursor-pointer ml-2  text-black  bg-white rounded-full px-3 py-1">
                                    <p className="mb-0">Close</p>
                                </div>
                            </div>

                            <ExchangeForm fetchId={`${profileData.data.business[0].user_id}`} fetchEmail={`${profileData.data.vcard[0].email}`} fetchFirstName={`${profileData.data.vcard[0].firstname}`} fetchLastName={`${profileData.data.vcard[0].lastname}`} onDismiss={handleDismissExchangeForm} />
                        </div>
                    </div>
                    <div
                        className={`h-full w-full z-20 left-0 bg-black bg-opacity-50 ${emailToggled ? "fixed" : "hidden"
                            }`}
                    >
                        <div className="max-w-2xl h-full relative mx-auto">
                            <FontAwesomeIcon
                                icon={faXmarkCircle}
                                className="top-5 left-5 absolute cursor-pointer text-white"
                                size="2x"
                                onClick={toggleEmail}
                            />

                            <div className="w-full bg-white space-y-3 absolute bottom-0 rounded-t-xl py-2 px-2">
                                <img
                                    src="../images/eazylinkBlack.png"
                                    alt="Eazy Link "
                                    className="w-1/3 sm:w-1/5 mx-auto"
                                />

                                <div className="flex flex-col gap-3 px-2">
                                    <a
                                        href={`tel:${profileData.data.vcard[0].user_phone}`}
                                        className="flex gap-2 items-end border-b-2 border-neutral-400 pb-2"
                                    >
                                        <span className="h-10 text-blue-600 text-center w-12 sm:w-16 text-xs sm:text-sm">
                                            Phone 1
                                        </span>
                                        <span className="flex-1 sm:text-lg">
                                            {profileData.data.vcard[0].user_phone}
                                        </span>
                                        <FontAwesomeIcon icon={faPhone} className="h-6 sm:h-8" />
                                    </a>
                                    {profileData.data.vcard[0].alt_phone != "" ? (
                                        <a
                                            href={`tel:${profileData.data.vcard[0].alt_phone}`}
                                            className="flex gap-2 items-end border-b-2 border-neutral-400 pb-2"
                                        >
                                            <span className="h-10 text-blue-600 text-center w-12 sm:w-16 text-xs sm:text-sm">
                                                Phone 2
                                            </span>
                                            <span className="flex-1 sm:text-lg">
                                                {profileData.data.vcard[0].alt_phone}
                                            </span>
                                            <FontAwesomeIcon icon={faPhone} className="h-6 sm:h-8" />
                                        </a>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className={`h-full w-full z-20 left-0 bg-black bg-opacity-50 ${displayMails ? "fixed" : "hidden"
                            }`}
                    >
                        <div className="max-w-2xl h-full relative mx-auto">
                            <FontAwesomeIcon
                                icon={faXmarkCircle}
                                className="top-5 left-5 absolute cursor-pointer text-white"
                                size="2x"
                                onClick={toggleMails}
                            />

                            <div className="w-full bg-white space-y-3 absolute bottom-0 rounded-t-xl py-2 px-2">
                                <img
                                    src="../images/eazylinkBlack.png"
                                    alt="Eazy Link"
                                    className="w-1/3 sm:w-1/5 mx-auto"
                                />

                                <div className="flex flex-col gap-3 px-2">
                                    <a
                                        href={`mailto:${profileData.data.vcard[0].email}`}
                                        className="flex gap-2 items-end border-b-2 border-neutral-400 pb-2"
                                    >
                                        <span className="h-10 text-blue-600 text-center w-12 sm:w-16 text-xs sm:text-sm">
                                            Email 1
                                        </span>
                                        <span className="flex-1 sm:text-lg">
                                            {profileData.data.vcard[0].email}
                                        </span>
                                        <FontAwesomeIcon icon={faEnvelope} className="h-6 sm:h-8" />
                                    </a>
                                    {profileData.data.vcard[0].alt_email != "" ? (
                                        <a
                                            href={`mailto:${profileData.data.vcard[0].alt_email}`}
                                            className="flex gap-2 items-end border-b-2 border-neutral-400 pb-2"
                                        >
                                            <span className="h-10 text-blue-600 text-center w-12 sm:w-16 text-xs sm:text-sm">
                                                Email 2
                                            </span>
                                            <span className="flex-1 sm:text-lg">
                                                {profileData.data.vcard[0].alt_email}
                                            </span>
                                            <FontAwesomeIcon icon={faEnvelope} className="h-6 sm:h-8" />
                                        </a>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        className={`h-full w-full z-20 left-0 bg-black bg-opacity-50 ${webToggled ? "fixed" : "hidden"
                            }`}
                    >
                        <div className="max-w-2xl h-full relative mx-auto">
                            <FontAwesomeIcon
                                icon={faXmarkCircle}
                                className="top-5 left-5 absolute cursor-pointer text-white"
                                size="2x"
                                onClick={toggleWeb}
                            />

                            <div className="w-full bg-white space-y-3 absolute bottom-0 rounded-t-xl py-2 px-2">
                                <img
                                    src="../images/eazylinkBlack.png"
                                    alt="Eazy Link "
                                    className="w-1/3 sm:w-1/5 mx-auto"
                                />

                                <div className="flex flex-col gap-3 px-2">
                                    {profileData.data?.socialmedia?.length > 0 && (
                                        <>
                                            {profileData.data.socialmedia[0].website && (
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={profileData.data.socialmedia[0].website}
                                                    className="flex gap-2 items-end border-b-2 border-neutral-400 pb-2"
                                                >
                                                    <span className="h-10 text-blue-600 text-center w-12 sm:w-16 text-xs sm:text-sm">
                                                        Web 1
                                                    </span>
                                                    <span className="flex-1 sm:text-lg">
                                                        {profileData.data.socialmedia[0].website}
                                                    </span>
                                                    <FontAwesomeIcon icon={faGlobe} className="h-6 sm:h-8" />
                                                </a>
                                            )}
                                            {profileData.data.socialmedia[0].website2 && (
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={profileData.data.socialmedia[0].website2}
                                                    className="flex gap-2 items-end border-b-2 border-neutral-400 pb-2"
                                                >
                                                    <span className="h-10 text-blue-600 text-center w-12 sm:w-16 text-xs sm:text-sm">
                                                        Web 2
                                                    </span>
                                                    <span className="flex-1 sm:text-lg">
                                                        {profileData.data.socialmedia[0].website2}
                                                    </span>
                                                    <FontAwesomeIcon icon={faGlobe} className="h-6 sm:h-8" />
                                                </a>
                                            )}
                                        </>
                                    )}
                                </div>

                            </div>
                        </div>
                    </div>
                    <div
                        className={`h-full w-full z-20 left-0 bg-black bg-opacity-50 ${shareToggled ? "fixed" : "hidden"
                            }`}
                    >
                        <div className="max-w-2xl h-full relative mx-auto">
                            <FontAwesomeIcon
                                icon={faXmarkCircle}
                                className="top-5 left-5 absolute cursor-pointer text-white"
                                size="2x"
                                onClick={toggleShare}
                            />

                            <div className="w-full bg-white space-y-3 text-black absolute bottom-0 rounded-t-xl py-2 px-2">
                                <p className="font-bold text-center">
                                    Scan QR code for my contact
                                </p>
                                <div className="flex flex-col gap-10 items-center">
                                    <img
                                        src={profileData.data.offline_qr}
                                        className="h-32 w-32"
                                    />
                                    {/* <span className="h-32 w-32 bg-red-500"></span>
                            <span className="h-32 w-32 bg-red-500"></span> */}
                                </div>
                                <div className="flex justify-center gap-5">
                                    <a
                                        href={`tel:${profileData.data.vcard[0].user_phone}`}
                                        className="w-10 h-10 sm:w-14 sm:h-14 p-2 bg-white shadow-md rounded-lg shadow-[#0000003b]"
                                    >
                                        <FontAwesomeIcon
                                            className="w-full h-full text-black"
                                            icon={faPhone}
                                        />
                                    </a>
                                    {
                                        profileData.data?.socialmedia?.[0]?.website ? (
                                            <a
                                                href={profileData.data.socialmedia[0].website}
                                                className="w-10 h-10 sm:w-14 sm:h-14 p-2 bg-white shadow-md rounded-lg shadow-[#0000003b]"
                                            >
                                                <FontAwesomeIcon className="w-full h-full text-black" icon={faGlobe} />
                                            </a>
                                        ) : null
                                    }
                                    {
                                        profileData.data?.vcard?.[0]?.email ? (
                                            <a
                                                href={`mailto:${profileData.data.vcard[0].email}`}
                                                className="w-10 h-10 sm:w-14 sm:h-14 p-2 bg-white shadow-md rounded-lg shadow-[#0000003b]"
                                            >
                                                <FontAwesomeIcon className="w-full h-full text-black" icon={faEnvelope} />
                                            </a>
                                        ) : null
                                    }
                                    {/* {
                                profileData.data?.socialmedia?.[0]?.whatsapp ? (
                                    <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`https://${profileData.data.socialmedia[0].whatsapp}`}
                                    className="w-10 h-10 sm:w-14 sm:h-14 p-2 bg-white shadow-md rounded-lg shadow-[#0000003b]"
                                    >
                                    <FontAwesomeIcon className="w-full h-full text-black" icon={faWhatsapp} />
                                    </a>
                                ) : null
                                }
                                {
                                profileData.data?.socialmedia?.[0]?.linkedin ? (
                                    <a
                                    href={profileData.data.socialmedia[0].linkedin}
                                    className="w-10 h-10 sm:w-14 sm:h-14 p-2 bg-white shadow-md rounded-lg shadow-[#0000003b]"
                                    >
                                    <FontAwesomeIcon className="w-full h-full text-black" icon={faLinkedin} />
                                    </a>
                                ) : null
                                } */}

                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Begining of the card */}
                    <div className="max-w-2xl absolute flex justify-between w-full px-5 z-10">
                        {/* <span className="w-10 h-10 text-white text-2xl mt-3 cursor-pointer">
                        <FontAwesomeIcon
                        icon={faLanguage}
                        className="w-full h-full object-cover"
                        />
                    </span>
                    <span className="w-10 h-10 text-white text-2xl mt-3 cursor-pointer">
                        <FontAwesomeIcon icon={faUserClock} />
                    </span> */}
                    </div>
                    <div className="h-40 relative rounded-b-3xl">

                        {profileData.data.business[0].cover_image ? (
                            <img
                                draggable="false"
                                src={`https://dashboard.eazylink.co/uploads/${profileData.data.business[0].cover_image}`}
                                alt="bg"
                                className="h-full w-full object-cover rounded-b-3xl"
                            />  
                        ) : (
                            <img
                                draggable="false"
                                src={bgDefaultImage}
                                alt=""
                                className="h-full w-full object-cover rounded-b-3xl"
                            />  
                        )}

                        {profileData.data.business[0].profile_image ? (
                            <img
                                draggable="false"
                                className="h-36 w-36 -bottom-1/2 absolute left-1/2 -translate-x-1/2 border-8 border-my-blue border-opacity-70 rounded-full object-cover object-top"
                                src={`https://dashboard.eazylink.co/uploads/${profileData.data.business[0].profile_image}`}
                                alt="Profile"
                            />
                        ) : (
                            <div className="h-36 w-36 -bottom-1/2 absolute left-1/2 -translate-x-1/2 border-8 border-my-blue border-opacity-70 rounded-full bg-gray-200 flex items-center justify-center text-4xl font-bold">
                                {getInitials(profileData.data.business[0].name)}
                            </div>
                        )}
                    </div>
                    <div className="mt-24 text-black">
                        <p className="text-center font-black text-black text-xl">
                            {profileData.data.business[0].name}
                        </p>
                        <p className="text-center flex flex-col items-center mt-1">
                            <span>{manualDecode(profileData.data.business[0].occupation)}</span>
                            <span>{manualDecode(profileData.data.vcard[0].company)}</span>
                        </p>
                    </div>

                    <div className="flex items-center gap-3 sm:gap-4 px-4 sm:px-12 justify-between mt-5">
                        <a href={`https://dashboard.eazylink.co/test-composer.php?business=${profileData.data.business[0].id}`} className="rounded-md text-sm justify-center gap-3 flex items-center flex-1">
                            <Card custom="rounded-md text-sm justify-center gap-3 flex items-center flex-1">
                                <span className="inset-0"></span>
                                <span>Save Contact</span>
                                <span className="w-5 h-5">
                                    <FontAwesomeIcon icon={faUserPlus} className="w-full h-full" />
                                </span>
                            </Card>
                        </a>

                        <Card
                            custom="rounded-md text-sm justify-center gap-3 flex items-center flex-1"
                            clickHandler={toggleSave}
                        >
                            <span>Exchange Contact</span>
                            <span className="w-5 h-5">
                                <FontAwesomeIcon icon={faShareNodes} className="w-full h-full" />
                            </span>
                        </Card>
                    </div>

                    {/* share contact card component */}
                    {/* <div className="flex items-center gap-4 px-4 sm:px-12 justify-between mt-5">
                        <Card
                            custom="rounded-md text-sm justify-center gap-3 py-2 flex items-center flex-1"
                            clickHandler={toggleShare}
                        >
                            <span>share</span>
                            <span className="w-5 h-5">
                                <FontAwesomeIcon icon={faShareNodes} className="w-full h-full" />
                            </span>

                        </Card>

                    </div> */}

                    {profileData.data.socialmedia.length > 0 && countNonEmptyFields(profileData.data.socialmedia[0]) ? (
                        <>
                            <div className="text-black flex items-center relative justify-between px-5 mt-8 font-semibold text-lg">
                                <span>Social Media</span>
                                <p className="group px-2 border">
                                    <FontAwesomeIcon icon={faQuestionCircle} />
                                    <span className="absolute text-sm group-hover:inline text-black bg-neutral-300 right-5 font-normal px-2 hidden py-0.5 -bottom-3/4 rounded-sm">
                                        Scroll left for more social links
                                    </span>
                                </p>
                            </div>
                            <div className="px-5 overflow-x-scroll socialIcons">
                                <div className="flex gap-5 min-w-max pb-5 items-center mt-2">
                                    {profileData.data.socialmedia[0].website && (
                                        <span
                                            onClick={toggleWeb}
                                            className="w-10 h-10 cursor-pointer sm:w-14 sm:h-14 p-2 bg-white shadow-md rounded-lg shadow-[#0000003b] flex items-center justify-center"
                                        >
                                            <FaGlobe className="w-full h-full text-blue-500" />
                                        </span>
                                    )}
                                    {profileData.data?.socialmedia?.[0]?.facebook && (
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={profileData.data.socialmedia[0].facebook}
                                            className="w-10 h-10 sm:w-14 sm:h-14 p-2 bg-white shadow-md rounded-lg shadow-[#0000003b] flex items-center justify-center"
                                        >
                                            <FaFacebook className="w-full h-full text-blue-600" />
                                        </a>
                                    )}
                                    {profileData.data.socialmedia[0].whatsapp && (
                                        <a
                                            target="_blank"
                                            href={`https://${profileData.data.socialmedia[0].whatsapp}`}
                                            className="w-10 h-10 sm:w-14 sm:h-14 p-2 bg-white shadow-md rounded-lg shadow-[#0000003b] flex items-center justify-center"
                                        >
                                            <FaWhatsapp className="w-full h-full text-green-500" />
                                        </a>
                                    )}
                                    {profileData.data.socialmedia[0].twitter && (
                                        <a
                                            href={profileData.data.socialmedia[0].twitter}
                                            className="w-10 h-10 sm:w-14 sm:h-14 p-2 bg-white shadow-md rounded-lg shadow-[#0000003b] flex items-center justify-center"
                                        >
                                            <FaXTwitter className="w-full h-full text-black" />
                                        </a>
                                    )}
                                    {profileData.data.socialmedia[0].snapchat && (
                                        <a
                                            href={profileData.data.socialmedia[0].snapchat}
                                            className="w-10 h-10 sm:w-14 sm:h-14 p-2 bg-white shadow-md rounded-lg shadow-[#0000003b] flex items-center justify-center"
                                        >
                                            <FaSnapchat className="w-full h-full text-yellow-400" />
                                        </a>
                                    )}
                                    {profileData.data.socialmedia[0].instagram && (
                                        <a
                                            href={profileData.data.socialmedia[0].instagram}
                                            className="w-10 h-10 sm:w-14 sm:h-14 p-2 bg-white shadow-md rounded-lg shadow-[#0000003b] flex items-center justify-center"
                                        >
                                            <FaInstagram className="w-full h-full text-pink-600" />
                                        </a>
                                    )}
                                    {profileData.data.socialmedia[0].linkedin && (
                                        <a
                                            href={profileData.data.socialmedia[0].linkedin}
                                            className="w-10 h-10 sm:w-14 sm:h-14 p-2 bg-white shadow-md rounded-lg shadow-[#0000003b] flex items-center justify-center"
                                        >
                                            <FaLinkedin className="w-full h-full text-blue-700" />
                                        </a>
                                    )}
                                    {profileData.data.socialmedia[0].youtube && (
                                        <a
                                            href={profileData.data.socialmedia[0].youtube}
                                            className="w-10 h-10 sm:w-14 sm:h-14 p-2 bg-white shadow-md rounded-lg shadow-[#0000003b] flex items-center justify-center"
                                        >
                                            <FaYoutube className="w-full h-full text-red-600" />
                                        </a>
                                    )}
                                    {profileData.data.socialmedia[0].vimeo && (
                                        <a
                                            href={profileData.data.socialmedia[0].vimeo}
                                            className="w-10 h-10 sm:w-14 sm:h-14 p-2 bg-white shadow-md rounded-lg shadow-[#0000003b] flex items-center justify-center"
                                        >
                                            <FaVimeo className="w-full h-full text-blue-500" />
                                        </a>
                                    )}
                                    {profileData.data.socialmedia[0].tumblr && (
                                        <a
                                            href={profileData.data.socialmedia[0].tumblr}
                                            className="w-10 h-10 sm:w-14 sm:h-14 p-2 bg-white shadow-md rounded-lg shadow-[#0000003b] flex items-center justify-center"
                                        >
                                            <FaTumblr className="w-full h-full text-blue-800" />
                                        </a>
                                    )}
                                    {profileData.data.socialmedia[0].tiktok && (
                                        <a
                                            href={profileData.data.socialmedia[0].tiktok}
                                            className="w-10 h-10 sm:w-14 sm:h-14 p-2 bg-white shadow-md rounded-lg shadow-[#0000003b] flex items-center justify-center"
                                        >
                                            <FaTiktok className="w-full h-full text-black" />
                                        </a>
                                    )}
                                    {profileData.data.socialmedia[0].reddit && (
                                        <a
                                            href={profileData.data.socialmedia[0].reddit}
                                            className="w-10 h-10 sm:w-14 sm:h-14 p-2 bg-white shadow-md rounded-lg shadow-[#0000003b] flex items-center justify-center"
                                        >
                                            <FaReddit className="w-full h-full text-orange-600" />
                                        </a>
                                    )}
                                    {profileData.data.socialmedia[0].pinterest && (
                                        <a
                                            href={profileData.data.socialmedia[0].pinterest}
                                            className="w-10 h-10 sm:w-14 sm:h-14 p-2 bg-white shadow-md rounded-lg shadow-[#0000003b] flex items-center justify-center"
                                        >
                                            <FaPinterest className="w-full h-full text-red-700" />
                                        </a>
                                    )}
                                </div>
                            </div>
                        </>
                    ) :
                        <div className="text-black flex items-center relative justify-between px-5 mt-10 font-semibold text-lg">
                            <span></span>
                            <p className="group px-2 border">
                                <span className="absolute text-sm group-hover:inline text-black bg-neutral-300 right-5 font-normal px-2 hidden py-0.5 -bottom-3/4 rounded-sm">
                                </span>
                            </p>
                        </div>}

                    {profileData.data.business[0].description && (
                        <About about={profileData.data.business[0].description} />
                    )}
                    {profileData.data.vcard[0].location && (
                        <Addresses address={manualDecode(profileData.data.vcard[0].location)} />
                    )}
                    {profileData.data.featured_products && profileData.data.featured_products.length > 0 && (
                        <Products featuredProducts={profileData.data.featured_products} />
                    )}
                    {profileData.data.business[0].calendly_link && (
                        <BookAppointment
                            name={profileData.data.vcard[0].firstname}
                            email={profileData.data.vcard[0].email}
                            calendlyLink={profileData.data.business[0].calendly_link}
                        />
                    )}
                    <PaymentsList 
                        momoNumber={profileData.data.business[0].momo_number}
                        flutterwaveUrl={profileData.data.business[0].flutterwave_url}
                        wiseUrl={profileData.data.business[0].wise_url}
                    />
                    {profileData.data.business[0].video_url && (
                        <EmbedVideo
                            videoUrl={profileData.data.business[0].video_url}
                            videoTitle={profileData.data.business[0].video_title}
                        />
                    )}
                    {profileData.data.gallery && profileData.data.gallery.length > 0 && (
                        <PhotoGallery photos={profileData.data.gallery} />
                    )}
                    <CardFooter />
                </>
            ) : (
                <p>No Data available. Contact <a href="https://eazylink.co">Eazylink Support</a> </p>
            )}
        </main>
    )
}

export default CardInfo