export default function Card({ children, custom, clickHandler }) {
  return (
    <span
      onClick={clickHandler}
      className={`${custom} bg-black cursor-pointer py-2 text-white`}
    >
      {children}
    </span>
  );
}
