import React from 'react'

const CardFooter = () => {
  return (
    <div className="mt-5 relative">
      <div className="absolute top-0 left-0 right-0 h-6 bg-white">
        <div className="absolute bottom-0 left-0 right-0 h-6 bg-black">
          <div className="absolute top-0 left-0 right-0 h-12 bg-white rounded-b-[10rem]"></div>
        </div>
      </div>
      
      {/* Main content */}
      <div className="bg-black pt-16 pb-2 text-sm sm:text-base text-white space-y-5 ">
        <img
          src="../images/eazylink.png"
          alt="Eazy Link"
          className="w-1/3 sm:w-1/5 mx-auto"
        />
        <p className="mt-3 px-5 text-center">
          Join other professionals who are Networking with Eazylink, everyday and Make
          a Lasting Impression
        </p>
        <div className="text-xs sm:text-base flex gap-2 sm:gap-5 justify-between px-5">
          <a className="w-full border-2 rounded-md text-center py-2" href="https://eazylink.co" target='_blank' rel="noopener noreferrer">
            Get Your Card Today
          </a>
          <a className="w-full border-2 rounded-md text-center py-2" href="https://dashboard.eazylink.co/login" target='_blank' rel="noopener noreferrer">
            Login
          </a>
        </div>
        <p className="text-center">&copy; Eazylink {new Date().getFullYear()}</p>
      </div>
    </div>
  )
}

export default CardFooter