import React from 'react'

const EmbedVideo = ({ videoUrl, videoTitle }) => {
  if (!videoUrl) return null;

  // Function to convert YouTube watch URL to embed URL
  const getEmbedUrl = (url) => {
    const videoId = url.split('v=')[1];
    if (videoId) {
      const ampersandPosition = videoId.indexOf('&');
      if (ampersandPosition !== -1) {
        return `https://www.youtube.com/embed/${videoId.substring(0, ampersandPosition)}`;
      }
      return `https://www.youtube.com/embed/${videoId}`;
    }
    return url; // Return original URL if it's not a standard YouTube watch URL
  };

  const embedUrl = getEmbedUrl(videoUrl);

  return (
    <div className="px-5 mt-10 bg-white pb-10 rounded-b-3xl relative z-10">
      <p className="text-my-blue font-semibold text-lg">Video</p>
      <iframe
        className="w-full h-56 mt-5 rounded-md"
        src={embedUrl}
        title={videoTitle || 'Embedded video'}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    </div>
  )
}

export default EmbedVideo