import React, { useState } from 'react'
import Card from './ui/Card/Card'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";

const Products = ({ featuredProducts }) => {
  if (!featuredProducts || featuredProducts.length === 0) return null;

  return (
    <div className="px-5 mt-8">
      <p className="text-black font-semibold text-lg">Featured</p>
      <div className="mt-3 flex flex-col gap-5">
        {featuredProducts.map((product, index) => (
          <a href={product.product_link.startsWith('http') ? product.product_link : `https://${product.product_link}`} key={index} target="_blank" rel="noopener noreferrer">
            <Card custom="rounded-md bg-black flex items-center gap-3 px-2">
              <span className="w-10 h-10 sm:w-14 sm:h-14 flex items-center justify-center">
                <ProductImage image={product.product_image} name={product.product_name} />
              </span>
              <p className="text-white text-sm sm:text-base">{product.product_name}</p>
            </Card>
          </a>
        ))}
      </div>
    </div>
  )
}

const ProductImage = ({ image, name }) => {
  const [imageError, setImageError] = useState(false);

  if (imageError) {
    return <FontAwesomeIcon icon={faImage} className="w-full h-full text-white" />;
  }

  return (
    <img
      src={`https://dashboard.eazylink.co/uploads/products/${image}`}
      alt={name}
      className="w-full h-full object-cover rounded-md"
      onError={() => setImageError(true)}
    />
  );
};

export default Products