import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import CardInfo from './CardInfo';
import SkeletonLoader from '../components/SkeletonLoader';
import he from 'he';

const UserProfile = () => {
  const { userId } = useParams();
  const location = useLocation();
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    // Check if the current path is the expected profile route
    if (!userId || !location.pathname.includes('/profile/')) {
      setError(true);
      setLoading(false);
      return;
    }

    const fetchData = async () => {
      try {
        const response = await axios.get(`https://dashboard.eazylink.co/get_profile.php?business=${userId}`);
        const data = response.data;

        if (data.data.socialmedia.length === 0) {
          data.data.socialmedia = [];
        }

        data.data.business = data.data.business.map(business => ({
          ...business,
          name: decodeHtmlEntities(business.name),
          occupation: decodeHtmlEntities(business.occupation),
          description: decodeHtmlEntities(business.description),
        }));

        setProfileData(data);
        setLoading(false);
        document.title = `UserProfile - ${userId || 'NoUserId'}`;
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(true);
        setLoading(false);
      }
    };

    fetchData();
    updateFavicon(userId);
  }, [userId, location.pathname]);

  const decodeHtmlEntities = (text) => {
    let decoded = text;
    let previous;
    do {
      previous = decoded;
      decoded = he.decode(decoded);
    } while (decoded !== previous);
    return decoded.replace(/&039;/g, "'");
  };

  const updateFavicon = (userId) => {
    const linkElement = document.querySelector("link[rel*='icon']") || document.createElement('link');
    linkElement.type = 'image/png';
    linkElement.rel = 'icon';
    linkElement.href = "https://eazylink.co/public/images/logo-icon.png";
    document.head.appendChild(linkElement);
  };

  if (loading) {
    return <SkeletonLoader />;
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-center bg-gray-100 border border-gray-400 text-gray-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">Card not found!</strong>
          <span className="block sm:inline"> contact <a href='https://eazylink.co' className='underline'>support</a></span>
        </div>
      </div>
    );
  }

  return (
    <main className="max-w-2xl mx-auto">
      <CardInfo profileData={profileData} />
    </main>
  );
};

export default UserProfile;
