import React from 'react';

const About = ({ about }) => {
    if (!about) return null;

    return (
        <div className="px-5 mt-5">
            <p className="text-black font-semibold text-lg">Bio</p>
            <p className="px-5 mt-3 sm:text-base text-sm">
                {about}
            </p>
        </div>
    );
};

export default About;