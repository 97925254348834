import React, {useState} from 'react';
import emailjs from 'emailjs-com';

const ExchangeForm = (props) => {
  const { fetchFirstName, fetchEmail, onDismiss } = props;
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const handleEmailClick = (e) => {
    e.preventDefault();

    // Get the form data
    const formData = {
      fullName: e.target.elements.fullName.value,
      phone: e.target.elements.phone.value,
      email: e.target.elements.email.value,
    };

    // Perform form validation
    if (!formData.fullName || !formData.phone || !formData.email) {
      alert('Please fill in all required fields.');
      return;
    }

    // EmailJS configuration
    const serviceID = 'service_21mqpg8';
    const templateID = 'template_ki560bj';
    const userID = 'WMGH06sX9SWVocVBz';



    const templateParams = {
      to_name: fetchFirstName,
      to_email: fetchEmail,
      from_name: formData.fullName,
      from_email: formData.email,
      from_phone: formData.phone,
    };

    emailjs.send(serviceID, templateID, templateParams, userID)
      .then((response) => {
        console.log('Sent successfully!', response.status, response.text);
        // Reset the form fields
        e.target.elements.fullName.value = '';
        e.target.elements.phone.value = '';
        e.target.elements.email.value = '';
        // Show a success message
        setShowSuccessMessage(true);
        setTimeout(() => {
          setShowSuccessMessage(false);
          setTimeout(() => {
            //Dismiss the form
            onDismiss();
          }, 1000);
        }, 3000);
    
      })
      .catch((error) => {
        console.error('Failed to send email:', error);
      });
  };

  return (
    <form
      onSubmit={handleEmailClick}
      className="w-full bg-white space-y-3 absolute bottom-0 rounded-t-xl py-5 px-5"
    >      
    {showSuccessMessage && (
      <div className="bg-green-500 text-white p-2 rounded-md">
        Sent successfully!
      </div>
    )}
      <p className="text-black font-bold sm:text-lg">
        Share your contact information...
      </p>
      <div className="flex flex-col">
        <label htmlFor="fullName">Full name *</label>
        <input
          type="text"
          name="fullName"
          required
          className="rounded-md border-2 py-1 px-1 border-neutral-500"
          id="fullName"
        />
      </div>
      <div className="flex flex-col">
        <label htmlFor="phone">Phone *</label>
        <input
          type="tel"
          name="phone"
          required
          className="rounded-md border-2 py-1 px-1 border-neutral-500"
          id="phone"
        />
      </div>
      <div className="flex flex-col">
        <label htmlFor="email">Email *</label>
        <input
          type="email"
          name="email"
          required
          className="rounded-md border-2 py-1 px-1 border-neutral-500"
          id="email"
        />
      </div>
      <button type="submit" className="block bg-black w-full py-3 text-white rounded-md">
        Send
      </button>
    </form>
  );
};

export default ExchangeForm;