import React from 'react';
import {Routes, Route, Navigate} from 'react-router-dom'
import UserProfile from './pages/UserProfile';

const App = () => {
  return (
    <Routes>
      <Route path="/profile/:userId" element={<UserProfile />} />
      <Route path="*" element={<RedirectToExternal />} />
    </Routes>
  );
};

const RedirectToExternal = () => {
  // Redirect to eazylink
  window.location.href = 'https://dashboard.eazylink.co/login';
  return null;
};

export default App;
