import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

const Addresses = ({ address }) => {
  if (!address) return null;

  return (
    <div className="px-5 mt-8">
      <p className="text-black font-semibold text-lg">Address</p>
      <a
        href={`https://maps.google.com/?q=${encodeURIComponent(address)}`}
        target="_blank"
        rel="noopener noreferrer"
        className="hover:text-gray-700 px-5 flex items-center gap-3 mt-3 sm:text-base text-sm group"
      >
        <span className="w-4 sm:w-6 h-4 sm:h-6 group-hover:text-red-500">
          <FontAwesomeIcon icon={faLocationDot} className="w-full h-full" />
        </span>
        <span>{address}</span>
      </a>
    </div>
  );
};

export default Addresses;