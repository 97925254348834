import React from 'react'
import Card from './ui/Card/Card'

const PaymentsList = ({ momoNumber, flutterwaveUrl, wiseUrl }) => {
  // nothing gets rendered if there are no payment methods
  if (!momoNumber && !flutterwaveUrl && !wiseUrl) {
    return null;
  }

  const paymentMethods = [
    flutterwaveUrl && {
      name: 'Flutterwave',
      icon: '/images/flutterwave.png',
      link: flutterwaveUrl,
    },
    wiseUrl && {
      name: 'Wise',
      icon: '/images/wise.jpg',
      link: wiseUrl,
    },
    momoNumber && {
      name: 'MOMO',
      icon: '/images/momo.png',
    }
  ].filter(Boolean); 

  // nothing gets rendered if there are no payment methods
  if (paymentMethods.length === 0) {
    return null;
  }

  const formatMomoNumber = (number) => {
    if (number == null) return ''; 
    const strNumber = String(number);
    return strNumber.startsWith('0') ? strNumber : '0' + strNumber;
  };

  const handlePayment = (method) => {
    if (method.name.toLowerCase() === 'flutterwave') {
      if (flutterwaveUrl) {
        const url = flutterwaveUrl.startsWith('http') ? flutterwaveUrl : `https://${flutterwaveUrl}`;
        window.open(url, '_blank', 'noopener,noreferrer');
      } else {
        console.log('Flutterwave payment link is not available.');
      }
    } 
    if (method.name.toLowerCase() === 'wise') {
      if (wiseUrl) {
        const url = wiseUrl.startsWith('http') ? wiseUrl : `https://${wiseUrl}`;
        window.open(url, '_blank', 'noopener,noreferrer');
      } else {
        console.log('Wise payment link is not available.');
      }
    }
     else if (method.name.toLowerCase() === 'momo') {
      const formattedNumber = formatMomoNumber(momoNumber);
      let ussdCode;
      if (formattedNumber.length < 8) {
        ussdCode = `*182*8*1*${formattedNumber}%23`;
      } else {
        ussdCode = `*182*1*1*${formattedNumber}%23`;
      }
      window.location.href = `tel:${ussdCode}`;
    }
  };

  return (
    <div className="px-5 mt-8">
      <p className="text-black font-semibold text-lg">Payments</p>
      <div className="mt-3 flex flex-col gap-5">
        {paymentMethods.map((method, index) => (
          <div key={index} onClick={() => handlePayment(method)}>
            <Card custom="rounded-md bg-black flex items-center gap-3 px-2 cursor-pointer">
              <span className="w-10 h-10 sm:w-14 sm:h-14 flex items-center justify-center">
                <img
                  src={method.icon}
                  className="h-full w-full object-cover rounded-md"
                  alt={method.name}
                />
              </span>
              <p className="text-white text-sm sm:text-base">Pay with {method.name}</p>
            </Card>
          </div>
        ))}
      </div>
    </div>
  )
}

export default PaymentsList
