import React from 'react'
import Card from './ui/Card/Card'

const BookAppointment = ({ name, email, calendlyLink }) => {
    return (
        <div className="px-5 mt-8">
          <p className="text-black font-semibold text-lg">
            Appointment booking
          </p>
          <div className="mt-3 flex flex-col gap-5">
            <a href={calendlyLink} target="_blank" rel="noopener noreferrer">
              <Card custom="rounded-md bg-black flex items-center gap-3 px-2">
                <span className="w-10 h-10 sm:w-14 sm:h-14">
                  <img
                    src="/images/calendly.webp"
                    className="h-full w-full object-cover rounded-md"
                    alt="Calendly"
                  />
                </span>
                <p className="text-white text-sm sm:text-base">Click here to book an appointment with {name}</p>
              </Card>
            </a>
          </div>
        </div>
    )
}

export default BookAppointment